import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../helpers/AuthContext";
import PublicNavBar from "../../../assets/components/Utils/PublicNavBar";
import { useNavigate } from "react-router-dom";
import { Chip } from "@mui/material";
import { getBookingFromUser } from "../../../helpers/api";

export default function BookingListScreen() {
  const { authUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBookingFromUser()
      .then((res) => {
        setBookings(res.data);
        
      })
      .catch((e) => {
        console.error(e);
                
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="publicSection">
      <section className="publicSectionContainer">
        <PublicNavBar authUser={authUser} />
        <section className="publicHeader">
          <div>
            <h1>Gérer vos réservations</h1>
            <p>Gérer vos réservations n'aura jamais été aussi simple !</p>
            <div className="publicHeaderButton">
              <button onClick={() => navigate("/")}>
                Créer une nouvelle réservation
              </button>
            </div>
          </div>
        </section>
        <section className="publicBookingList">
            {loading ? <p>Chargement...</p> : bookings.map((booking) => {
                return (
                    <article onClick={() => navigate(`/booking/${booking.id}`)} key={booking.id}>
                        <div className="publicBookingListDetails">
                            <div>
                                <h2>{booking.event.name}</h2>
                            </div>
                            <div className="publicBookingListInfo">
                                {booking.content.map((item) => {
                                    return (
                                        <Chip label={`${item.quantity} x ${item.pricingName}`} color="success" />
                                    )
                                })}
                                {booking.status === "paid" && <Chip label="Payé" color="success" />}
                                {booking.status === "canceled" && <Chip label="Annulé" color="error" />}
                                {booking.status === "not_paid" && <Chip label="Impayé" color="error" />}
                                {booking.status === "partial" && <Chip label="Paiement Partiel" color="warning" />}
                            </div>
                        </div>
                    </article>
                )
            })}
        </section>
      </section>
    </div>
  );
}
