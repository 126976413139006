import { useEffect, useState } from "react";
import NavBar from "../../../../assets/components/Utils/NavBar";
import { createBooking, createBookingWithRegister, getEvent, getEvents } from "../../../../helpers/api";
import moment from "moment";
import "moment/locale/fr";
import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Swal from "sweetalert2";

moment.locale("fr");

export default function NewBookingScreen({ authUser }) {
  const [booking, setBooking] = useState({
    eventId: 0,
    name: "",
    type: "discussion",
    content: [],
    paymentMethod: "espece",
  });
  const [bookingWithRegister, setBookingWithRegister] = useState({
    eventId: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "0",
    type: "barricot",
    content: [],
    receipt: false,
    comment: "",
    paymentMethod: "cb",
    newsletter: false,
  });
  const [eventList, setEventList] = useState([]);
  const [withRegister, setWithRegister] = useState("");
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true);

  const updateEventId = (id) => {
    getEvent(id).then((res) => {
      setEvent(res.data);
    });
    setBooking({
      ...booking,
      eventId: id,
    });
  };

  const handleCreateBooking = () => {
    createBooking(booking)
      .then((res) => {
        setWithRegister("");
        Swal.fire({
          title: "Réservation créée",
          text: "La réservation a bien été créée",
          icon: "success",
          confirmButtonText: "Super !",
        });
      })
      .catch((e) => {
        Swal.fire({
          title: "Erreur",
          text: "Une erreur est survenue lors de la création de la réservation",
          icon: "error",
          confirmButtonText: "Compris !",
        });
      });
  };

  const handleCreateBookingWithRegister = () => {
    createBookingWithRegister(bookingWithRegister)
      .then((res) => {
        setWithRegister("");
        Swal.fire({
          title: "Réservation créée",
          text: "La réservation a bien été créée et un mail de confirmation a été envoyé.",
          icon: "success",
          confirmButtonText: "Super !",
        });
      })
      .catch((e) => {
        Swal.fire({
          title: "Erreur",
          text: "Une erreur est survenue lors de la création de la réservation",
          icon: "error",
          confirmButtonText: "Compris !",
        });
      });
  };

  useEffect(() => {
    getEvents().then((events) => {
      setEventList(events.data.currentEvents);
      if (events.data.currentEvents.length === 1 && booking.eventId === 0) {
        setBooking({
          ...booking,
          eventId: events.data.currentEvents[0].id,
        });
        setBookingWithRegister({
          ...bookingWithRegister,
          eventId: events.data.currentEvents[0].id
        })
        getEvent(events.data.currentEvents[0].id).then((res) => {
          setEvent(res.data);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  }, []);

  if (loading) {
    return (
      <main>
        <NavBar authUser={authUser} />

        <section className="dashboardContainer">
          <section className="dashboardHeader">
            <div>
              <h1>Nous recherchons les événements en cours</h1>
              <p>
                Nous sommes en train de récupérer les données, merci de
                patienter !
              </p>
            </div>
          </section>
        </section>
      </main>
    );
  } else {
    if (withRegister) {
      // S'il veux s'inscrire
      return (
        <main>
          <NavBar authUser={authUser} />
          <section className="dashboardContainer">
            <section className="dashboardHeader">
              <div>
                <h1>Nouvelle réservation</h1>
                <p>Créez une nouvelle réservation pour un événement</p>
              </div>
            </section>

            {booking.eventId === 0 ? (
              <section className="dashboardForm">
                {eventList.map((event) => {
                  return (
                    <article
                      key={event.id}
                      onClick={() => {
                        updateEventId(event.id);
                      }}
                    >
                      <Chip label={event.name} color="success" />
                    </article>
                  );
                })}
              </section>
            ) : (
              <section className="dashboardForm">
                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Prénom"
                  variant="standard"
                  value={bookingWithRegister.firstName}
                  color="success"
                  onChange={(e) =>
                    setBookingWithRegister({ ...bookingWithRegister, firstName: e.target.value })
                  }
                />
                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Nom"
                  variant="standard"
                  value={bookingWithRegister.lastName}
                  color="success"
                  onChange={(e) =>
                    setBookingWithRegister({
                      ...bookingWithRegister,
                      lastName: e.target.value,
                    })
                  }
                />
                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Email"
                  variant="standard"
                  color="success"
                  onChange={(e) =>
                    setBookingWithRegister({
                      ...bookingWithRegister,
                      email: e.target.value,
                    })
                  }
                />
                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Téléphone"
                  variant="standard"
                  color="success"
                  onChange={(e) =>
                    setBookingWithRegister({
                      ...bookingWithRegister,
                      phone: e.target.value,
                    })
                  }
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">De quel manière c'est faite la réservation ?</InputLabel>
                  <Select
                    sx={{ width: "100%", marginBottom: "1rem" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={bookingWithRegister.type}
                    defaultValue={"discussion"}
                    variant="standard"
                    color="success"
                    label="Type "
                    onChange={(e) =>
                      setBookingWithRegister({ ...bookingWithRegister, type: e.target.value })
                    }
                  >
                    <MenuItem value={"barricot"}>Barricot</MenuItem>
                    <MenuItem value={"discussion"}>Discussion</MenuItem>
                    <MenuItem value={"mail"}>Mail</MenuItem>
                    <MenuItem value={"phone"}>Téléphone</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Un reçu sera nécessaire ?</InputLabel>
                  <Select
                    sx={{ width: "100%", marginBottom: "1rem" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={bookingWithRegister.receipt}
                    defaultValue={"discussion"}
                    variant="standard"
                    color="success"
                    label="Type "
                    onChange={(e) =>
                      setBookingWithRegister({ ...bookingWithRegister, receipt: e.target.value })
                    }
                  >
                    <MenuItem value={false}>Non</MenuItem>
                    <MenuItem value={true}>Oui</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Méthode de paiement
                  </InputLabel>
                  <Select
                    sx={{ width: "100%", marginBottom: "1rem" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={bookingWithRegister.paymentMethod}
                    variant="standard"
                    color="success"
                    label="Méthode de paiement"
                    onChange={(e) =>
                      setBookingWithRegister({ ...bookingWithRegister, paymentMethod: e.target.value })
                    }
                  >
                    <MenuItem value={"cb"}>Carte Bancaire</MenuItem>
                    <MenuItem value={"espece"}>Espèce</MenuItem>
                    <MenuItem value={"cheque"}>Chèque</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Newsletter</InputLabel>
                  <Select
                    sx={{ width: "100%", marginBottom: "1rem" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={bookingWithRegister.newsletter}
                    defaultValue={"discussion"}
                    variant="standard"
                    color="success"
                    label="Type "
                    onChange={(e) =>
                      setBookingWithRegister({ ...bookingWithRegister, newsletter: e.target.value })
                    }
                  >
                    <MenuItem value={false}>Non</MenuItem>
                    <MenuItem value={true}>Oui</MenuItem>
                  </Select>
                </FormControl>

                <p style={{ color: "#fff"}}>On passe aux formules :</p>

                {event.pricing.map((pricing) => {
                  return (
                    <TextField
                      type="number"
                      label={pricing.name}
                      defaultValue={0}
                      sx={{ width: "100%", marginBottom: "1rem" }}
                      onChange={(e) => {

                        const newContent = bookingWithRegister.content.map((item) =>
                          item.pricingId === pricing.id
                            ? { ...item, quantity: e.target.value }
                            : item
                        );

                        if (
                          !newContent.some(
                            (item) => item.pricingId === pricing.id
                          )
                        ) {
                          newContent.push({
                            pricingId: pricing.id,
                            pricingName: pricing.name,
                            pricingPrice: pricing.price,
                            quantity: e.target.value,
                          });
                        }

                        setBookingWithRegister({
                          ...bookingWithRegister,
                          content: newContent,
                        });
                      }}
                      variant="standard"
                      color="success"
                    />
                  );
                })}

                <button
                  className="dashboardNewEventButton"
                  onClick={() => handleCreateBookingWithRegister()}
                >
                  Créer la réservation
                </button>
              </section>
            )}
          </section>
        </main>
      );
    } else if (withRegister === "") {
      // S'il ne sais pas
      return (
        <main>
          <NavBar authUser={authUser} />
          <section className="dashboardContainer">
            <section className="dashboardHeader">
              <div>
                <h1>Merci de choisir un type !</h1>
                <p>En fonction de votre choix, nous pourrons envoyé des mails à l'utilisateur pour lui confirmer sa réservation.</p>
              </div>
            </section>
            <section className="dashboardForm">
            <button
                className="dashboardNewEventButton"
                onClick={() => setWithRegister(false)}
              >
                Sans création d'utilisateur
              </button>
              <button
                className="dashboardNewEventButton"
                onClick={() => setWithRegister(true)}
              >
                Avec création d'utilisateur
              </button>
            </section>
          </section>
        </main>
      );
    } else {
      // S'il ne veux pas s'inscrire
      return (
        <main>
          <NavBar authUser={authUser} />
          <section className="dashboardContainer">
            <section className="dashboardHeader">
              <div>
                <h1>Nouvelle réservation</h1>
                <p>Créez une nouvelle réservation pour un événement</p>
              </div>
            </section>

            {booking.eventId === 0 ? (
              <section className="dashboardForm">
                {eventList.map((event) => {
                  return (
                    <article
                      key={event.id}
                      onClick={() => {
                        updateEventId(event.id);
                      }}
                    >
                      <Chip label={event.name} color="success" />
                    </article>
                  );
                })}
              </section>
            ) : (
              <section className="dashboardForm">
                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Nom et Prénom"
                  variant="standard"
                  color="success"
                  onChange={(e) =>
                    setBooking({ ...booking, name: e.target.value })
                  }
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    sx={{ width: "100%", marginBottom: "1rem" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={event.paymentMethod}
                    defaultValue={"discussion"}
                    variant="standard"
                    color="success"
                    label="Type "
                    onChange={(e) =>
                      setBooking({ ...booking, type: e.target.value })
                    }
                  >
                    <MenuItem value={"barricot"}>Barricot</MenuItem>
                    <MenuItem value={"discussion"}>Discussion</MenuItem>
                    <MenuItem value={"mail"}>Mail</MenuItem>
                    <MenuItem value={"phone"}>Téléphone</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Méthode de paiement
                  </InputLabel>
                  <Select
                    sx={{ width: "100%", marginBottom: "1rem" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={event.paymentMethod}
                    variant="standard"
                    color="success"
                    label="Méthode de paiement"
                    onChange={(e) =>
                      setBooking({ ...booking, paymentMethod: e.target.value })
                    }
                  >
                    <MenuItem value={"cb"}>Carte Bancaire</MenuItem>
                    <MenuItem value={"espece"}>Espèce</MenuItem>
                    <MenuItem value={"cheque"}>Chèque</MenuItem>
                  </Select>
                </FormControl>

                {event.pricing.map((pricing) => {
                  return (
                    <TextField
                      type="number"
                      label={`Combien de formule : ${pricing.name}`}
                      defaultValue={0}
                      sx={{ width: "100%", marginBottom: "1rem" }}
                      onChange={(e) => {

                        const newContent = booking.content.map((item) =>
                          item.pricingId === pricing.id
                            ? { ...item, quantity: e.target.value }
                            : item
                        );

                        if (
                          !newContent.some(
                            (item) => item.pricingId === pricing.id
                          )
                        ) {
                          newContent.push({
                            pricingId: pricing.id,
                            pricingName: pricing.name,
                            pricingPrice: pricing.price,
                            quantity: e.target.value,
                          });
                        }

                        setBooking({
                          ...booking,
                          content: newContent,
                        });
                      }}
                      variant="standard"
                      color="success"
                    />
                  );
                })}

                <button
                  className="dashboardNewEventButton"
                  onClick={() => handleCreateBooking()}
                >
                  Créer la réservation
                </button>
              </section>
            )}
          </section>
        </main>
      );
    }
  }
}
