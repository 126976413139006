import NavBar from "../../../../assets/components/Utils/NavBar";
import { Chip, TextField } from "@mui/material";
import { getPayments } from "../../../../helpers/api";
import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

export default function PaymentScreen({ authUser }) {
  const navigate = useNavigate();
  const [payments, setPayments] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);

  const handleSearch = (event, value) => {
    if (value) {
      setPayments(payments.filter((payment) => payment.id === value.id));
    } else {
      getPayments().then((res) => {
        setPayments(res.data);
      });
    }
  };

  useEffect(() => {
    getPayments().then((res) => {
      setPayments(res.data);
      setAutoCompleteValue(
        res.data.map((payment) => ({ label: payment.reservation.user
          ? `${payment.reservation.user.first_name} ${payment.reservation.user.last_name}`
          : payment.reservation.name, id: payment.id }))
      );
    });
  }, []);

  return (
    <main>
      <NavBar authUser={authUser} />
      <section className="dashboardContainer">
        <section className="dashboardHeaderSearch">
          <div>
            <h1>Liste des paiements 🔥</h1>
            <p>Vous avez une liste de <span>{payments.length} paiements</span> disponible :D</p>
          </div>
          <div className="dashboardHeaderSearchInput">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={autoCompleteValue}
              sx={{ width: 300 }}
              onChange={handleSearch}
              renderInput={(params) => (
                <TextField {...params} label="Rechercher par nom" variant="standard" color="success" />
              )}
            />
          </div>
        </section>

        <section className="dashboardTable">
          {payments.map((payment) => {
            return (
              <article key={payment.id} className="dashboardBookingItem" onClick={() => navigate(`/dashboard/payment/${payment.id}`)}>
                <div className="dashboardBookingItemContainer">
                  <div>
                    <h2>
                      {payment.reservation.user
                        ? `${payment.reservation.user.first_name} ${payment.reservation.user.last_name}`
                        : payment.reservation.name}
                    </h2>
                        <p>Effectué le {moment(payment.created_at).format("dddd D MMMM YYYY à HH:mm")}</p>
                  </div>
                  <p>
                  {" "}
                    {payment.reservation.receipt === true ? (
                      <Chip label={"Reçu demandé"} color="warning" />
                    ) : (
                      <Chip label={"Sans reçu"} color="success" />
                    )}
                    {" "}
                    <span>
                      <Chip
                        label={`${payment.content.reduce((total, content) => {
                          return total + (content.pricingPrice * content.quantity);
                        }, 0)} €`}
                        color="success"
                      />
                    </span>
                  </p>
                </div>
              </article>
            );
          })}
        </section>
      </section>
    </main>
  );
}
