import { useState } from "react";
import logo_color from "../assets/imgs/logo_color.png";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { createAccount } from "../helpers/api";
import { TextField } from "@mui/material";

export default function ResetPasswordScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("to");
  const code = queryParams.get("code");

  const handleCreateAccount = (e) => {
    e.preventDefault();
    if (password === "" || passwordConfirm === "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "warning",
        title: "Champs vides",
        html: "Veuillez remplir les champs de connexion.",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      if (password === passwordConfirm) {
        if (password.length >= 8) {
          let timerInterval;
          Swal.fire({
            toast: true,
            icon: "info",
            showConfirmButton: false,
            title: "Modification du mot de passe en cours...",
            html: "Nous vérifions vos informations de connexion.",
            timer: 2000,
            position: "top-end",
            timerProgressBar: true,
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              createAccount({
                email: email,
                password: password,
                code: code,
              })
                .then(() => {
                  // Si la modification est réussie.
                  Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "success",
                    title: "Compte créé",
                    html: "Votre mot de passe a bien été mis à jour, vous pouvez maintenant vous connecter.",
                    showConfirmButton: false,
                    timer: 2000,
                  }).then(() => {
                    if (result.dismiss === Swal.DismissReason.timer) {
                      navigate("/connexion");
                    }
                  });
                })
                .catch(() => {
                  Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "error",
                    title: "Erreur",
                    html: "Une erreur est survenue lors de la modification de votre mot de passe.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                });
            }
          });
        } else {
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: "warning",
            title: "Mot de passe trop court",
            html: "Votre mot de passe doit contenir au moins 8 caractères.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          title: "Mots de passe différents",
          html: "Les mots de passe ne correspondent pas.",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  return (
    <main className="authSection">
      <section className="authRow">
        <form onSubmit={(e) => handleCreateAccount(e)}>
          <div onClick={() => navigate("/")}>
            <img src={logo_color} alt="Le logo du Comité des Fêtes de Pomps" />
          </div>
          <div>
            <h1>Mettre à jour votre mot de passe...</h1>
            <p>On vous pardonne, oublier, ça arrive même aux meilleurs !</p>
          </div>
          <div className="authInput">
          <TextField
              sx={{ width: "100%", marginBottom: "1rem" }}
              id="password"
              type="password"
              label="Mot de passe"
              autoFocus
              variant="standard"
              color="success"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <TextField
              sx={{ width: "100%", marginBottom: "1rem" }}
              id="password-confirm"
              label="Confirmation du mot de passe"
              type="password"
              variant="standard"
              color="success"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              required
            />
          </div>
          <div>
            <button type="submit">Confirmer la modification</button>
          </div>
        </form>
      </section>
    </main>
  );
}
