import { useContext, useEffect, useState } from "react";
import PublicNavBar from "../assets/components/Utils/PublicNavBar";
import AuthContext from "../helpers/AuthContext";
import { Link, useParams } from "react-router-dom";
import { createBookingFromUser, getEvent } from "../helpers/api";
import PrivacyStep from "../assets/components/FormStepper/PrivacyStep";
import CguStep from "../assets/components/FormStepper/CguStep";
import NameStep from "../assets/components/FormStepper/NameStep";
import ReceiptStep from "../assets/components/FormStepper/ReceiptStep";
import FormuleStep from "../assets/components/FormStepper/FormuleStep";
import CommentStep from "../assets/components/FormStepper/CommentStep";
import NewsletterStep from "../assets/components/FormStepper/NewsletterStep";
import FinishStep from "../assets/components/FormStepper/FinishStep";
import Swal from "sweetalert2";

export default function NewBookingScreen() {
  const { authUser } = useContext(AuthContext);
  const [event, setEvent] = useState(null);
  const { id } = useParams();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [reservation, setReservation] = useState({
    eventId: id,
    userId: authUser?.id,
    firstName: authUser?.first_name,
    lastName: authUser?.last_name,
    email: authUser?.email,
    phone: authUser?.phone,
    type: "web",
    content: [],
    receipt: false,
    comment: "",
    paymentMethod: "cb",
    newsletter: false,
  });

  useEffect(() => {
    getEvent(id).then((response) => {
      setEvent(response.data);
    });
  }, [id]);

  const handleSubmitFinishBooking = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    createBookingFromUser(reservation)
      .then((response) => {
        console.log("On est bon.");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur est survenue lors de la réservation, veuillez réessayer ultérieurement.",
        });
      });
  };

  return (
    <div className="publicSection">
      <section className="publicSectionContainer">
        <PublicNavBar authUser={authUser} reserve={true} />
      </section>
      {step === 0 ? (
        <PrivacyStep stepUpdate={setStep} actualStep={step} />
      ) : step === 1 ? (
        <CguStep stepUpdate={setStep} actualStep={step} />
      ) : step === 2 ? (
        <NameStep
          stepUpdate={setStep}
          actualStep={step}
          reservation={reservation}
          setReservation={setReservation}
          authUser={authUser}
        />
      ) : step === 3 ? (
        <ReceiptStep
          stepUpdate={setStep}
          actualStep={step}
          reservation={reservation}
          setReservation={setReservation}
        />
      ) : step === 4 ? (
        <FormuleStep
          stepUpdate={setStep}
          actualStep={step}
          reservation={reservation}
          setReservation={setReservation}
          event={event}
        />
      ) : step === 5 ? (
        <CommentStep
          stepUpdate={setStep}
          actualStep={step}
          reservation={reservation}
          setReservation={setReservation}
        />
      ) : step === 6 ? (
        <NewsletterStep
          stepUpdate={setStep}
          actualStep={step}
          reservation={reservation}
          setReservation={setReservation}
          finish={handleSubmitFinishBooking}
        />
      ) : step === 7 ? (
        <FinishStep
          reservation={reservation}
        />
      ) : null}
      <section className="publicFooter">
        <div className="publicFooterContainer">
          <p>
            Développé par <Link>Bryaan</Link> avec ❤️ | © 2024 Tous droits
            réservés
          </p>

          <div className="publicFooterLinks">
            <Link>Conditions générales d'Utilisation</Link>
            <Link>Politique de confidentialité</Link>
            <Link>Mentions légales</Link>
          </div>
        </div>
      </section>
    </div>
  );
}
