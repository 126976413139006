import { useParams } from "react-router-dom";
import NavBar from "../../../../assets/components/Utils/NavBar";
import { useEffect, useState } from "react";
import { getPayment } from "../../../../helpers/api";
import { Chip } from "@mui/material";
import moment from "moment";
import "moment/locale/fr";
import Swal from "sweetalert2";
moment.locale("fr");

export default function EditPaymentScreen({ authUser }) {
    const { id } = useParams();
    const [payment, setPayment] = useState({});
    const [loading, setLoading] = useState(true);

    const handleReceipt = () => {
        Swal.fire({
            title: "Êtes-vous sûr ?",
            text: "Voulez-vous vraiment envoyer un reçu à cet utilisateur ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4caf50",
            confirmButtonText: "Oui, envoyer un reçu !",
            cancelButtonText: "Annuler",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Cette fonctionnalité n'est pas encore disponible.. 😅",
                    text: "Nous travaillons dur pour vous proposer cette fonctionnalité très bientôt !",
                    icon: "info",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "D'accord !",
                });
            }
        });
    };

    useEffect(() => {
        getPayment(id).then((res) => {
            setPayment(res.data);
            setLoading(false);
        });
    }, [id]);

    if (loading) {
        return (
            <main>
                <NavBar authUser={authUser} />
                <section className="dashboardContainer">
                    <section className="dashboardHeader">
                        <div>
                            <h1>Chargement du paiement 🤙</h1>
                            <p>Nous chargeons les informations du paiement...</p>
                        </div>
                    </section>
                </section>
            </main>
        );
    } else {
        return (
            <main>
                <NavBar authUser={authUser} />
                <section className="dashboardContainer">
                    <section className="dashboardHeaderSearch">
                        <div>
                            <h1>Consultation du réglement 🤙</h1>
                            <p>
                                Ce paiement a été enregistré le{" "}
                                <span>{moment(payment.created_at).format("DD MMMM YYYY à HH:mm")}</span>{" "}
                                par {payment.collector?.first_name} {payment.collector?.last_name} {""}
                                pour {payment.reservation.event.name}.
                            </p>
                        </div>
                        <div className="dashboardHeaderSearchInput">
                            <Chip label={`💳 ${payment.payment_method === "cb"
                                ? "Carte Bancaire"
                                : payment.payment_method === "cheque"
                                    ? "Chèque"
                                    : "Espèces"
                                }`} color="success" /> {" "}

                            <Chip label={`🎟️ ${payment.reservation.receipt === true
                                ? "Reçu nécessaire"
                                : 'Aucun reçu'
                                }`} color="warning" />
                        </div>
                    </section>
                    <section className="dashboardTable">
                        <article
                            className="dashboardBookingItem"
                        >
                            <div className="dashboardBookingItemContainer">
                                <h2>
                                    {payment.reservation.user?.first_name
                                        ? `${payment.reservation.user.last_name} ${payment.reservation.user.first_name}`
                                        : payment.reservation.name}
                                </h2>
                                <p>
                                    <span>
                                        📍{" "}
                                        {payment.reservation.user?.email
                                            ? payment.reservation.user?.email
                                            : "Aucune adresse mail renseignée"}
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        📞{" "}
                                        {payment.reservation.user?.phone
                                            ? payment.reservation.user?.phone
                                            : "Aucun téléphone renseigné"}
                                    </span>
                                </p>
                                <p>
                                    <Chip label={`💳 ${payment.transactionId ?
                                        payment.transactionId
                                        : "Aucun identifiant de transaction"
                                        }`} color="success" />
                                </p>
                            </div>
                        </article>
                    </section>
                    <section className="dashboardTable">
                        {payment.content.map((item, index) => {
                            return (
                                <article className="dashboardBookingItem" key={index}>
                                    <div className="dashboardBookingItemContainer">
                                        <h2>
                                            📝 {item.pricingName}{" "}
                                            <Chip label={`${item.quantity * item.pricingPrice} €`} />{" "}
                                        </h2>

                                        <div className="dashboardBookingItemEditQuantity">
                                            <button
                                                disabled
                                            >
                                                -
                                            </button>
                                            <input
                                                disabled
                                                type="number"
                                                value={item.quantity}
                                                min={0}
                                                max={50}
                                                step={1}
                                            />
                                            <button
                                                disabled
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                </article>
                            );
                        })}
                        <article className="dashboardBookingItem">
                            <div className="dashboardBookingItemContainer">
                                <h2>
                                    📦 Montant total
                                </h2>
                                <h2>
                                    {payment.content.reduce((acc, item) => {
                                        return acc + item.quantity * item.pricingPrice;
                                    }, 0)}
                                    €
                                </h2>
                            </div>
                        </article>
                    </section>

                    <section className="dashboardBookingItemButton">
                        <button
                            className="dashboardBookingItemButtonTotal"
                            onClick={() => handleReceipt()}
                        >
                            Envoyer un reçu
                        </button>
                    </section>
                </section>
            </main>
        );
    }
}
