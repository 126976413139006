import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../assets/css/styles.css";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import mairie_pomps from "../assets/imgs/partners/pomps.png";
// import cclb from "../assets/imgs/partners/4486ee_79d3e63c49ee4484b44b420bc56724f6~mv2_d_2083_1592_s_2.webp";
import eventBackground from "../assets/imgs/event.jpg";
import facebook from "../assets/imgs/social/facebook.png";
import instagram from "../assets/imgs/social/instagram.png";
import { useContext, useEffect, useState } from "react";
import { getEvents } from "../helpers/api";
import moment from "moment";
import AuthContext from "../helpers/AuthContext";
import PublicNavBar from "../assets/components/Utils/PublicNavBar";
import Footer from "../assets/components/Utils/Footer";
import Swal from "sweetalert2";

export default function HomeScreen() {
  const { authUser } = useContext(AuthContext);
  
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);

  const newReservation = (event) => {
    if(event.status === "in_progress") {
      Swal.fire({
        icon: "error",
        title: "Aie !",
        html: "<strong>Merci de bien vouloir vous rapprochez de notre secrétariat au <a href='tel:0780980903'>07 809 809 03</a> afin de vérifier si nous pouvons rajouté votre réservation.</strong>",
      });
      return;
    }
    navigate(`/event/${event.id}/new`);
  }


  useEffect(() => {
    getEvents().then((response) => {
      setEvents(response.data.currentEvents);
    });
  }, []);

  return (
    <div className="publicSection">
      <section className="publicSectionContainer">
        <PublicNavBar authUser={authUser} />

        <section className="publicHeader">
          <div>
            <h1>
              Réservez en ligne,
              <br /> c’est <span>facile</span>, plus <span>rapide</span> et
              <br /> vous ne paierez pas plus cher !
            </h1>
            <p>
              Votre comité se modernise et vous propose de réserver vous-même,
              sans notre aide depuis une plateforme spécialement conçue à cet
              effet.
            </p>
            <div className="publicHeaderButton">
              <button onClick={() => window.scrollTo({ top: 700, behavior: "smooth" })}>
                Réservez dès maintenant <FontAwesomeIcon icon={faArrowRight} />
              </button>
              <button>En savoir plus ...</button>
            </div>
          </div>
        </section>
        <section className="publicPartners">
          <h6>Ils nous accompagnent dans nos événements</h6>
          <div>
            <Link to="https://pomps.fr?utm_campaign=cdf_pomps">
              <img src={mairie_pomps} alt="Partenaire" />
            </Link>
            {/* <Link to="https://www.cclb64.fr?utm_campaign=cdf_pomps">
              <img src={cclb} alt="Partenaire" />
            </Link> */}
          </div>
        </section>

        {events.map((event) => {
          return (
            <section className="publicEvent" key={event.id} id="events">
              <article>
                <img src={eventBackground} alt="Partenaire" />
                <div className="publicEventDetails">
                  <div className="publicEventHeader">
                    <h4>{event.name}</h4>
                    <p>
                      Du {moment(event.date_start).format("D MMMM YYYY")} au{" "}
                      {moment(event.date_end).format("D MMMM YYYY")}
                    </p>
                  </div>

                  <p className="publicEventDescription">
                    Le Comité des Fêtes vous convie, comme chaque année à
                    l’habituel repas des Fêtes de Pomps. Voyons un peu le menu
                    de cette année : <br />
                    <br />
                    - Assiette Pompsoise (gésier, salade, tomate et lardon)
                    <br />
                    - Poulet sauce champignon + Gratin Dauphinois <br />
                    - Fromage local <br />
                    - Brownie accompagnée d'une crème anglaise <br />
                    <br />
                    C’est le moment de réserver votre place !
                  </p>

                  <div className="publicEventButton">
                    <button
                      onClick={() => newReservation(event)}
                    >
                      Commencer ma réservation{" "}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                    {/* <button onClick={() => getAllergens()}>Voir les allergènes</button> */}
                  </div>
                </div>
              </article>
            </section>
          );
        })}

        {events.length === 0 ? (
          <section className="publicEventNothing">
            <article>
              <div className="publicEventDetails">
                <div className="publicEventHeader">
                  <h4>
                    Nous sommes navrés, mais il n'y a actuellement aucun événement
                    disponible à la réservation...
                  </h4>
                </div>

                <p className="publicEventDescription">
                  Nous vous remercions infiniement pour votre fidélité et votre
                  soutien. Nous espérons vous retrouver très prochainement lors de
                  nos prochains événements.
                </p>
              </div>
            </article>
          </section>
        ) : ""}

        <section className="publicSocial">
          <div>
            <h2>
              Suivez <span>nos aventures</span> en nous <br />
              suivant sur les réseaux sociaux !
            </h2>
            <p>
              Nous apprécions vous partagez les moments importants, l’actualité
              du comité, les photos souvenirs des manifestations organisé et
              l’actualité locale.
            </p>
          </div>
          <div>
            <Link to="https://www.facebook.com/comitedepomps">
              <img src={facebook} alt="Facebook" />
            </Link>
            <Link to="https://www.instagram.com/comitedepomps">
              <img src={instagram} alt="Instagram" />
            </Link>
          </div>
        </section>
        <Footer />
      </section>
    </div>
  );
}
