import { useContext, useEffect, useState } from "react";
import PublicNavBar from "../../../assets/components/Utils/PublicNavBar";
import { getBooking, updateBooking } from "../../../helpers/api";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../helpers/AuthContext";
import Swal from "sweetalert2";

export default function EditBookingScreen() {
  const { authUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formule, setFormule] = useState([]);

  const handleUpdateBookingContent = () => {   
    if(booking.content === formule) {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Aucune modification n'a été apportée à la réservation.",
        });
        return;
    }

    updateBooking({
      id: booking.id,
      content: formule,
    }).then((res) => {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "success",
        title: "La quantité a bien été modifiée !",
      });
      setBooking({
        ...booking,
        content: formule,
      });
    });
  };

  const handleFormule = (formuleId, quantity) => {
    if (quantity < 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La quantité ne peut pas être négative !",
      });
      return;
    }

    if (quantity > 20) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La quantité ne peut pas être supérieure à 20 ! Merci de prendre contact avec nous pour une réservation de groupe.",
      });
      return;
    }
    let newFormule = formule.map((item) => {
      if (item.pricingId === formuleId) {
        item.quantity = quantity;
      }
      return item;
    });

    setFormule(newFormule);
  };

  useEffect(() => {
    getBooking(id)
      .then((res) => {
        setBooking(res.data);
        if(res.data.user_id !== authUser.id) navigate("/");
        setFormule(res.data.content);
      })
      .catch((e) => {
        console.error(e);
        
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, authUser.id, navigate]);

  if (loading) {
    return <p>Chargement...</p>;
  } else {
    return (
      <div className="publicSection">
        <section className="publicSectionContainer">
          <PublicNavBar authUser={authUser} />
          <section className="publicHeader">
            <div>
              <h1>Modifier votre réservation</h1>
              <p>
                Pour le moment, vous pouvez uniquement modifier le nombre de
                personne.
              </p>
            </div>
          </section>
          <section className="publicBookingList">
            {booking.content.map((content) => {
              return (
                <article key={content.princingId}>
                  <div className="publicBookingListDetails">
                    <div>
                      <h2>{content.pricingName}</h2>
                    </div>
                    <div className="publicFormuleSelector">
                      <button
                        onClick={() =>
                          handleFormule(
                            content.pricingId,
                            formule.filter(
                              (item) => item.pricingId === content.pricingId
                            )[0].quantity - 1
                          )
                        }
                      >
                        -
                      </button>
                      <input
                        type="number"
                        value={
                          booking.content.filter(
                            (item) => item.pricingId === content.pricingId
                          )[0].quantity
                        }
                        onChange={
                          (e) =>
                          handleFormule(content.pricingId, e.target.value)
                        }
                      />
                      <button
                        onClick={() =>
                          handleFormule(
                            content.pricingId,
                            formule.filter(
                              (item) => item.pricingId === content.pricingId
                            )[0].quantity + 1
                          )
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                </article>
              );
            })}
          </section>
          <button className="publicEditConfirmButton" onClick={() => handleUpdateBookingContent()}>Confirmer les modifications</button>
        </section>
      </div>
    );
  }
}
