import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
let axiosAuthInterceptor = null;

export const axiosRemoveToken = () => {
    if (axiosAuthInterceptor !== null) {
        axios.interceptors.request.eject(axiosAuthInterceptor)
        axiosAuthInterceptor = null
    }
}

export const axiosSetToken = (token) => {
    if (axiosAuthInterceptor === null) {
        axiosAuthInterceptor = axios.interceptors.request.use((config) => {
            config.headers['Authorization'] = `Bearer ${token}`
            return config
        })
    }
}

export const getPing = async () => {
    return await axios.get(`${API_URL}/ping`);
}

export const login = async (credentials) => {
    return await axios.post(`${API_URL}/login`, credentials)
}

export const authCheck = async () => {
    return await axios.get(`${API_URL}/user`)
}

export const createAccount = async (account) => {
    return await axios.put(`${API_URL}/users/reset`, account)
}

export const createAccountFromAdmin = async (account) => {
    return await axios.post(`${API_URL}/user/new`, account)
}

export const getUsers = async () => {
    return await axios.get(`${API_URL}/users`)
}

export const getUser = async (id) => {
    return await axios.get(`${API_URL}/user/${id}`)
}

export const updateUser = async (user) => {
    return await axios.put(`${API_URL}/user/${user.id}`, user)
}

export const updateUserPassword = async (user) => {
    return await axios.put(`${API_URL}/user/${user.id}/password`)
}

export const getEvents = async () => {
    return await axios.get(`${API_URL}/events`)
}

export const getEvent = async (id) => {
    return await axios.get(`${API_URL}/event/${id}`)
}

export const updateEvent = async (event) => {
    console.log(event);
    return await axios.put(`${API_URL}/event/${event.id}`, event)
}

export const createEvent = async (event) => {
    return await axios.post(`${API_URL}/event`, event)
}

export const createPricing = async (pricing) => {
    return await axios.post(`${API_URL}/event/${pricing.eventId}/pricing`, pricing)
}

export const getPricing = async (id) => {
    return await axios.get(`${API_URL}/pricing/${id}`)
}

export const updatePricing = async (pricing) => {
    return await axios.put(`${API_URL}/pricing/${pricing.id}`, pricing)
}

export const deletePricing = async (id) => {
    return await axios.delete(`${API_URL}/pricing/${id}`)
}

export const createBooking = async (booking) => {
    return await axios.post(`${API_URL}/reservation`, booking)
}

export const createBookingWithRegister = async (booking) => {
    return await axios.post(`${API_URL}/reservationWithRegister`, booking)
}


export const createBookingFromUser = async (booking) => {
    return await axios.post(`${API_URL}/public/reservation`, booking)
}

export const getBookings = async () => {
    return await axios.get(`${API_URL}/reservations`)
}

export const getBooking = async (id) => {
    return await axios.get(`${API_URL}/reservation/${id}`)
}

export const getBookingFromUser = async () => {
    return await axios.get(`${API_URL}/reservations/user`)
}   

export const updateBooking = async (booking) => {
    return await axios.put(`${API_URL}/reservation/${booking.id}`, booking)
}

export const deleteBooking = async (booking) => {
    return await axios.delete(`${API_URL}/reservation/${booking.id}`)
}

export const createPayment = async (payment) => {
    return await axios.post(`${API_URL}/reservation/${payment.reservationId}/payment`, payment)
}

export const getPayments = async () => {
    return await axios.get(`${API_URL}/payments`)
}

export const getPayment = async (id) => {
    return await axios.get(`${API_URL}/payment/${id}`)
}

export const getLogs = async () => {
    return await axios.get(`${API_URL}/logs`)
}

export const getDashboardStats = async () => {
    return await axios.get(`${API_URL}/dashboard/stats`)
}

export const getDashboardLiteStats = async () => {
    return await axios.get(`${API_URL}/dashboard/stats/count`)
}