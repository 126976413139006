import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavBar from "../../../../assets/components/Utils/NavBar";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  getUser,
  updateUser,
  updateUserPassword,
} from "../../../../helpers/api";
import Swal from "sweetalert2";

export default function EditUserScreen({ authUser }) {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [bookings, setBookings] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const handleAddBookingToUser = () => {
    Swal.fire({
      title: "Cette fonctionnalité n'est pas encore disponible 😭",
      text: "Nous travaillons dur pour vous offrir une expérience de qualité. Nous vous invitons a revenir plus tard.",
      icon: "error",
      confirmButtonText: "Fermer",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUser(user).then((res) => {
      console.log(res);
      Swal.fire({
        title: "Utilisateur modifié",
        text: "L'utilisateur a bien été modifié",
        icon: "success",
        confirmButtonText: "Cool !",
      });
    });
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    updateUserPassword(user).then((res) => {
      Swal.fire({
        title: "Un lien pour la création de son mot de passe a été envoyé",
        text: "Le compte a temporairement été désactivé. L'utilisateur doit cliquer sur le lien reçu par email pour créer son mot de passe.",
        icon: "success",
        confirmButtonText: "Cool !",
      });
    });
  };

  useEffect(() => {
    getUser(id).then((res) => {
      setUser(res.data.user);
      setBookings(res.data.bookings);
      setLoading(false);
    });
  }, [id]);

  if (loading) {
    return (
      <main>
        <NavBar authUser={authUser} />
        <section className="dashboardContainer">
          <section className="dashboardHeaderSearch">
            <div>
              <h1>Modifier l'utilisateur 🔥</h1>
            </div>
          </section>
          <section className="dashboardEditEventContainer">
            <h2>Chargement...</h2>
          </section>
        </section>
      </main>
    );
  } else {
    return (
      <main>
        <NavBar authUser={authUser} />
        <section className="dashboardContainer">
          <section className="dashboardHeaderSearch">
            <div>
              <h1>Modifier l'utilisateur 🔥</h1>
            </div>
          </section>

          <section className="dashboardEditEventContainer">
            <form className="dashboardEventEditRow">
              <h2>Informations générales</h2>
              <div className="dashboardNewEventInputRow">
                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Identifiant"
                  disabled
                  variant="standard"
                  color="success"
                  defaultValue={id}
                />
                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Prénom"
                  variant="standard"
                  color="success"
                  type="text"
                  defaultValue={user.first_name}
                  onChange={(e) =>
                    setUser({ ...user, first_name: e.target.value })
                  }
                />

                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Nom de famille"
                  variant="standard"
                  color="success"
                  type="text"
                  defaultValue={user.last_name}
                  onChange={(e) =>
                    setUser({ ...user, last_name: e.target.value })
                  }
                />

                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Email"
                  variant="standard"
                  color="success"
                  type="email"
                  defaultValue={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                />

                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Téléphone"
                  variant="standard"
                  color="success"
                  type="phone"
                  defaultValue={user.phone}
                  onChange={(e) => setUser({ ...user, phone: e.target.value })}
                />

                <FormControl
                  variant="standard"
                  sx={{ minWidth: "100%", margin: "10px 0" }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Permission
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={user.permission}
                    onChange={(e) =>
                        setUser({ ...user, permission: e.target.value })
                      }
                    label="Permission"
                  >
                    <MenuItem value={"user"}>Utilisateur</MenuItem>
                    <MenuItem value={"staff"}>Membre du comité</MenuItem>
                    <MenuItem value={"developper"}>Développeur</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  variant="standard"
                  sx={{ minWidth: "100%", margin: "10px 0" }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Newsletter
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={user.newsletter}
                    onChange={(e) =>
                        setUser({ ...user, newsletter: e.target.value })
                      }
                    label="Newsletter"
                  >
                    <MenuItem value={true}>Oui</MenuItem>
                    <MenuItem value={false}>Non</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <button
                className="dashboardNewEventButton"
                onClick={(e) => handleSubmit(e)}
              >
                Modifier l'utilisateur
              </button>
              <button
                className="dashboardDeleteEventButton"
                onClick={(e) => handleUpdatePassword(e)}
              >
                Modifier le mot de passe
              </button>
            </form>
            <section className="dashboardEventPrincingRow">
              <h2>Gestion des réservations</h2>
              <div className="dashboardEventPricingList">
                <article
                    onClick={() => handleAddBookingToUser()}
                  style={{ backgroundColor: "#A0A0A0", textAlign: "center" }}
                >
                  <h3>Attribuer une réservation</h3>
                  <FontAwesomeIcon icon={faPlus} fontSize={36} />
                </article>

                {bookings.map((booking) => {
                  return (
                    <article
                      key={booking.id}
                      onClick={() => {
                        navigate(`/dashboard/booking/${booking.id}`);
                      }}
                    >
                      <div className="dashboardEventDetails">
                        <div className="dashboardEventResume">
                          <h3>{booking.event.name}</h3>
                          <p>
                            {booking.status === "not_paid" ? (
                              <Chip
                                sx={{ overflow: "visible" }}
                                label="Non payé"
                                color="error"
                              />
                            ) : (
                              ""
                            )}
                            {booking.status === "paid" ? (
                              <Chip
                                sx={{ overflow: "visible" }}
                                label="Payé"
                                color="success"
                              />
                            ) : (
                              ""
                            )}
                            {booking.status === "canceled" ? (
                              <Chip
                                sx={{ overflow: "visible" }}
                                label="Annulé"
                                color="error"
                              />
                            ) : (
                              ""
                            )}
                            {booking.status === "partial" ? (
                              <Chip
                                sx={{ overflow: "visible" }}
                                label="Partiellement payé"
                                color="info"
                              />
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                        <div>
                          <p className="dashboardEventCount">
                            <span>
                              {booking.content.reduce(
                                (sum, item) => sum + item.quantity,
                                0
                              )}
                            </span>
                          </p>
                          <p className="dashboardEventTitle">
                            personne
                            {booking.content.reduce(
                              (sum, item) => sum + item.quantity,
                              0
                            ) > 1
                              ? "s"
                              : ""}
                          </p>
                        </div>
                      </div>
                    </article>
                  );
                })}
              </div>
            </section>
          </section>
        </section>
      </main>
    );
  }
}
